import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { ReactComponent as EsqueciMinhaSenhaIcon } from '../../assets/icons/iconEsqueciMinhaSenha.svg';
import ROUTES_NAME from '../../consts/routes-name';
import CustomButton from '../../components/CustomButton/CustomButton';
import { AutenticationActions } from '../../store/ducks/autentication';
import * as session from '../../utils/session';

export default function UsuarioValidadoPage() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <>
            <Grid
                container
                className={classes.root}
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                    sm={7}
                    md={5}
                    xl={3}
                    elevation={0}
                    className={classes.containerGrid}
                >
                    <EsqueciMinhaSenhaIcon
                        className={classes.imagemEsqueciMinhaSenha}
                    />

                    <Typography variant="h1" className={classes.titulo}>
                        Primeiro acesso
                    </Typography>

                    <Typography variant="h2" className={classes.subTitulo}>
                        Seu usuário foi validado com sucesso.
                    </Typography>

                    <Typography variant="h3" className={classes.subTitulo}>
                        Para acessar a área logada, faça seu login.
                    </Typography>

                    <Grid item xs={12}>
                        <CustomButton
                            className={classes.button}
                            content="VOLTAR PARA LOGIN"
                            variant="contained"
                            onClickCallBack={() => {
                                dispatch(
                                    AutenticationActions.setIsValidadoUsuario(
                                        false,
                                    ),
                                );
                                if (session.getChatbot())
                                    history.push(ROUTES_NAME.HOME);
                                else history.push(ROUTES_NAME.LOGIN);
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
