/* eslint-disable import/no-named-as-default */
/* eslint-disable global-require */
// #region Imports

import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import useStyles from './styles';

// #endregion

const ScreenLoader = forwardRef((_, ref) => {
    const styles = useStyles();
    const [control, setControl] = useState(false);

    useImperativeHandle(ref, () => ({
        handleControl() {
            setControl(!control);
        },
    }));

    return (
        <Backdrop className={styles.backdrop} open={control}>
            <Box className={styles.logoBorder}>
                <img
                    src={require('../../assets/images/logoLoading.png')}
                    alt="logobb-loading"
                />
            </Box>
        </Backdrop>
    );
});

export default ScreenLoader;
