import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconErro } from '../../assets/images';
import { AutenticationActions } from '../../store/ducks/autentication';
import CustomButton from '../CustomButton/CustomButton';
import CustomLoading from '../CustomLoading/CustomLoading';
import CustomModal from '../CustomModal/CustomModal';
import ReactGTM from '../../utils/gtm';

const { setAutenticateMessage } = AutenticationActions;

export default function LoadingErrorMessage() {
    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const [showModal, setShowModal] = useState(false);

    const { autenticacao } = store;

    const errorMessage = autenticacao?.message;

    const isLoading = autenticacao?.loading;

    const handleCloseModal = () => {
        //* * Google Analytics */
        ReactGTM.event({
            event: 'modal',
            action: 'close',
            name: errorMessage,
        });

        setShowModal(false);

        if (autenticacao?.message) {
            dispatch(setAutenticateMessage(null));
        }
    };

    useEffect(() => {
        const hasErro = Boolean(errorMessage);
        if (window.location.pathname !== '/primeiro-acesso') {
            //* * Google Analytics */
            ReactGTM.event({
                event: 'modal',
                action: 'open',
                name: errorMessage,
            });
            setShowModal(hasErro);
        }
    }, [autenticacao.message, errorMessage]);

    return (
        <>
            {isLoading && <CustomLoading open={isLoading} />}{' '}
            {autenticacao?.message && (
                <CustomModal
                    open={showModal}
                    title={errorMessage}
                    icon={<IconErro />}
                    body={
                        <Grid container alignItems="center" direction="column">
                            <CustomButton
                                content="ENTENDI"
                                onClickCallBack={handleCloseModal}
                            />
                        </Grid>
                    }
                    handleClose={handleCloseModal}
                />
            )}
        </>
    );
}
