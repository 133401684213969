// #region Imports

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useMemo, useState } from 'react';
import LGPD from '../../../../consts/text/lgpd';
import { genFieldNameAndError } from '../../../../library/utils';
import FIELDS from '../../../../library/acesso-dados-fields';
import { useStyles } from '../styles';
import { appColors } from '../../../../AppTheme';
// #endregion

const { INFO_MEIO } = LGPD;

const MeioInfoFields = ({
    disabled = false,
    register,
    errors,
    isTelaConsulta,
}) => {
    const styles = useStyles();
    const [meioInfo, setMeioInfo] = useState('email');

    const handleRadioChange = (event) => {
        setMeioInfo(event.target.value);
    };

    const fieldProps = useMemo(
        () =>
            meioInfo === 'email'
                ? {
                      placeholder: INFO_MEIO.EMAIL.PLACEHOLDER,
                      className: styles.field,
                      ...genFieldNameAndError(errors, FIELDS.EMAIL),
                  }
                : {
                      multiline: true,
                      rows: 4,
                      placeholder: INFO_MEIO.CORRESPONDENCIA.PLACEHOLDER,
                      className: styles.fieldBox,
                      ...genFieldNameAndError(errors, FIELDS.CORRENSPONDENCIA),
                  },
        [meioInfo, errors, styles.field, styles.fieldBox],
    );

    return (
        <>
            <Box color={appColors.gray} marginTop="20px" paddingY="10px">
                <Typography variant="subtitle1">
                    {isTelaConsulta
                        ? INFO_MEIO.CONSULTA_MEIO
                        : INFO_MEIO.OUTRAS_MEIO}
                </Typography>
            </Box>
            <FormGroup row>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        value={meioInfo}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel
                            value="email"
                            control={
                                <Radio color="primary" disabled={disabled} />
                            }
                            label={INFO_MEIO.EMAIL.THIS}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="correspondencia"
                            control={
                                <Radio color="primary" disabled={disabled} />
                            }
                            label={INFO_MEIO.CORRESPONDENCIA.THIS}
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl>
            </FormGroup>
            <FormGroup row>
                <TextField
                    inputRef={register}
                    disabled={disabled}
                    variant="outlined"
                    {...fieldProps}
                />
            </FormGroup>
        </>
    );
};

export default MeioInfoFields;
