/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers';
import {
    Grid,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ROUTES_NAME from '../../consts/routes-name';
import {
    FORM_LOGIN_FIELDS,
    FORM_LOGIN_LABELS,
} from '../../consts/text/form-login';
import HOME from '../../consts/text/home';
import LOGIN from '../../consts/text/login';
import { AutenticationActions } from '../../store/ducks/autentication';
import history from '../../utils/history';
import * as session from '../../utils/session';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomInputForm from '../../components/CustomInputForm/CustomInputForm';
import { formSchemaLogin } from './form-schema';
import { useStyles } from './styles';
/**
 * @FormLogin componente criado para logar no sistema.
 * @criadoEm 18/01/2022
 */

const { NAO_CORRENTISTA } = HOME;

export default function FormLogin({ isNaoCorrentista = false }) {
    const classes = useStyles({ isNaoCorrentista });
    const dispatch = useDispatch();

    const { errors, register, handleSubmit, reset } = useForm({
        resolver: yupResolver(formSchemaLogin),
        defaultValues: {
            [FORM_LOGIN_FIELDS.FIELD_EMAIL]: '',
        },
    });

    const { autenticacao } = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setShowPassword(!showPassword);
    };

    const submitFormLogin = (_data) => {
        session.setUserName(_data[FORM_LOGIN_FIELDS.FIELD_EMAIL]);
        const data = {
            ..._data,
            user_name: _data[FORM_LOGIN_FIELDS.FIELD_EMAIL],
        };
        dispatch(AutenticationActions.postLoginRequest({ data }));
    };

    useEffect(() => {
        if (autenticacao.opcaoEnvio) {
            history.push(ROUTES_NAME.VALIDACAO);
        }
    }, [autenticacao.opcaoEnvio]);

    useEffect(() => {
        setTimeout(() => {
            reset();
        }, 100);
    }, []);

    return (
        <>
            <Grid
                item
                container
                justifyContent="flex-start"
                spacing={2}
                direction="row"
            >
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.titulo}>
                        {isNaoCorrentista ? NAO_CORRENTISTA.TITLE : LOGIN.TITLE}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.subTitulo}>
                        {isNaoCorrentista
                            ? NAO_CORRENTISTA.DESCRIPTION
                            : LOGIN.DESCRIPTION}
                    </Typography>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <CustomInputForm
                    checkValid
                    inputRef={register}
                    errors={errors}
                    label={FORM_LOGIN_LABELS.LABEL_EMAIL}
                    name={FORM_LOGIN_FIELDS.FIELD_EMAIL}
                    data-gtm-form="input"
                    data-gtm-name="email"
                />
            </Grid>
            <Grid item xs={12}>
                <CustomInputForm
                    inputRef={register}
                    errors={errors}
                    type={showPassword ? 'text' : 'password'}
                    label={FORM_LOGIN_LABELS.LABEL_SENHA}
                    name={FORM_LOGIN_FIELDS.FIELD_SENHA}
                    data-gtm-form="input"
                    data-gtm-name="senha"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                className={classes.iconeVisibility}
                            >
                                {showPassword ? (
                                    <VisibilityOutlinedIcon />
                                ) : (
                                    <VisibilityOffOutlinedIcon />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>
            <Grid container justifyContent="flex-start" item>
                <Typography
                    className={classes.link}
                    onClick={() => history.push(ROUTES_NAME.PRIMEIRO_ACESSO)}
                    data-gtm-type="click"
                    data-gtm-clicktype="link"
                    id="primeiro-acesso"
                    data-gtm-name="primeiro-acesso"
                >
                    {HOME.PRIMEIRO_ACESSO}
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    className={classes.link}
                    onClick={() => history.push(ROUTES_NAME.ESQUECI_SENHA)}
                    data-gtm-type="click"
                    data-gtm-clicktype="link"
                    id="esqueci-senha"
                    data-gtm-name="esqueci-senha"
                >
                    {NAO_CORRENTISTA.ESQUECI_SENHA}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <CustomButton
                    className={classes.Button}
                    content={
                        isNaoCorrentista
                            ? FORM_LOGIN_LABELS.BTN
                            : FORM_LOGIN_LABELS.BTN_ACESSAR
                    }
                    variant="contained"
                    onClickCallBack={handleSubmit(submitFormLogin)}
                    gtm={{
                        id: isNaoCorrentista ? 'acessar' : 'acessar-portal',
                        'data-gtm-name': 'acessar',
                    }}
                />
            </Grid>
        </>
    );
}
