/* eslint-disable import/no-extraneous-dependencies */
import { Backdrop, CircularProgress } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useStyles } from './styles';

/**
 *
 * @CustomLoading Componente para exibir uma tela de carregamento
 * @CriadoEm 18/05/2020
 */

export default function CustomLoading({ open, zIndex }) {
    const classes = useStyles({ zIndex });
    return (
        <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress variant="indeterminate" color="inherit" />
        </Backdrop>
    );
}
/**
 * @porp open {bool} indica que o componente está aberto,
 */
CustomLoading.propTypes = {
    open: PropTypes.bool.isRequired,
};
