/* eslint-disable import/no-extraneous-dependencies */
import { Box, debounce, Grid, IconButton } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useInterval } from '../../hooks/useInterval';
import { randomHashStr } from '../../utils/helpers';
import { useStyles } from './styles';

export default function CustomCarousel({
    children,
    showDots,
    showArrows,
    activeTimer,
    timer = null,
    itemsLength,
}) {
    const dots = Array.from(Array(children?.length ?? 0));
    const uniqKey = randomHashStr();

    const [activeDot, setActiveDot] = useState(0);
    const [mousedOver, setMousedOver] = React.useState(false);

    const moveScroll = (i) => {
        const slide = document.getElementById(`${uniqKey}-slide-items`);
        const item = document.getElementById(`${uniqKey}-slide-0`);

        if (slide && item) {
            const index = i ?? Math.round(slide.scrollLeft / item.offsetWidth);
            slide.scrollLeft = index * item.offsetWidth;

            setActiveDot(index);
        }
    };

    const handleMoveScroll = (index) => {
        if (index >= 0 && index < children?.length) {
            moveScroll(index);
        }
    };

    const handleOnScroll = debounce(() => moveScroll(), 200);
    const classes = useStyles();

    useInterval(
        () => {
            if (!mousedOver) {
                const newSlide = (activeDot + 1) % itemsLength;
                handleMoveScroll(newSlide);
            }
        },
        activeTimer ? 2000 : timer,
    );

    if (children?.length === 0) return null;

    return (
        <Grid container justifyContent="center">
            <Grid
                item
                id={`${uniqKey}-slide-items`}
                className={classes.slide}
                onScroll={handleOnScroll}
                onMouseOver={() => activeTimer && setMousedOver(true)}
                onMouseOut={() => activeTimer && setMousedOver(false)}
            >
                {children?.map((element, i) => (
                    <Box
                        minWidth={1}
                        id={`${uniqKey}-slide-${i}`}
                        key={`${uniqKey}-slide-${i}`}
                        className={classes.box}
                    >
                        {element}
                    </Box>
                ))}
            </Grid>
            {showDots && (
                <Grid item container>
                    <div className={classes.dots}>
                        {showArrows && (
                            <IconButton
                                data-gtm-type="click"
                                data-gtm-clicktype="button"
                                data-gtm-name="left-arrow"
                                onClick={() => {
                                    handleMoveScroll(activeDot - 1);
                                }}
                            >
                                <NavigateBefore />
                            </IconButton>
                        )}
                        {dots.map((_, i) => {
                            let css = classes.dot;

                            if (i === activeDot) {
                                css = clsx(classes.dot, classes.active);
                            }

                            return (
                                <div
                                    role="presentation"
                                    className={css}
                                    key={`dt-${i}`}
                                    onClick={() => {
                                        handleMoveScroll(i);
                                    }}
                                />
                            );
                        })}
                        {showArrows && (
                            <IconButton
                                data-gtm-type="click"
                                data-gtm-clicktype="button"
                                data-gtm-name="right-arrow"
                                onClick={() => {
                                    handleMoveScroll(activeDot + 1);
                                }}
                            >
                                <NavigateNext />
                            </IconButton>
                        )}
                    </div>
                </Grid>
            )}
        </Grid>
    );
}

CustomCarousel.propTypes = {
    showDots: PropTypes.bool,
    showArrows: PropTypes.bool,
};

CustomCarousel.defaultProps = {
    showDots: true,
    showArrows: true,
};
