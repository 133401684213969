/* eslint-disable react/no-danger */
import React from 'react';
import { format, isValid } from 'date-fns';
import * as vanillaMasker from '../../assets/js/vanilla-min';

const cnpjPattern = '99.999.999/9999-99';
const cpfPattern = '999.999.999-99';

export const queryParamsToObj = (query) => {
    if (!query) return null;

    return query
        .replace('?', '')
        .split('&')
        .reduce((acum, item) => {
            const [key, val] = item.split('=');
            acum[key] = val;
            return acum;
        }, {});
};

export const getURLParceiro = (value) => {
    let refererOrigem = '';

    if (value) {
        const url = new URL(value);
        refererOrigem = `https://${url.hostname}/`;
    }

    return refererOrigem;
};

export const replaceDotToComma = (value) =>
    value ? value.toString().replace('.', ',') : value;

export const onlyNums = (value) =>
    value ? replaceDotToComma(value).replace(/[^0-9]/g, '') : value;

export const onlyNumsString = (value) =>
    value ? value.toString().replace(/[^0-9]/g, '') : value;

export const onlyNumsAndXString = (value) =>
    value ? value.toString().replace(/[^0-9Xx]/g, '') : value;

export const CNPJ = (value) =>
    value ? vanillaMasker.toPattern(onlyNumsString(value), cnpjPattern) : value;

export const CPF = (value) =>
    value ? vanillaMasker.toPattern(onlyNumsString(value), cpfPattern) : value;

export const CPFandCNPJ = (value) =>
    value && value.length <= cpfPattern.length ? CPF(value) : CNPJ(value);

export const formatMoney = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);

export const formatMoneyNumbersOnly = (value) =>
    new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
    }).format(value);

export const formatDateUTC = (date) => {
    const dt = new Date(date);

    if (isValid(dt)) {
        return format(dt, 'dd/MM/yyyy');
    }
    if (date && date.length > 10) {
        return date.substring(0, 10).split('-').reverse().join('/');
    }

    return '';
};

export const formatCaptalized = (value) => {
    const splitedValue = value && value.split(' ');
    let captalizedValue = '';

    if (splitedValue && splitedValue.length > 0) {
        splitedValue.forEach((val) => {
            let newVal = val.toLowerCase();
            newVal = newVal.charAt(0).toUpperCase() + newVal.slice(1);
            captalizedValue += `${newVal} `;
        });
    } else {
        return value;
    }

    return captalizedValue;
};

export const capitalizeFullName = (text) => {
    if (!text) return '';

    const blacklist = ['da', 'de', 'di', 'do', 'du'];

    const capitalize = text
        .split(' ')
        .reduce((acum, t) => {
            const val = t.toLowerCase();
            if (val) {
                const str = !blacklist.includes(val)
                    ? val
                          .charAt(0)
                          .toUpperCase()
                          .concat(val.substr(1).toLowerCase())
                    : val;
                acum.push(str);
            }
            return acum;
        }, [])
        .join(' ');

    return capitalize;
};

export const addCaracterCep = (value) => {
    return [value && value.slice(0, 5), '-', value && value.slice(5)].join('');
};

export const telefone = (value) => {
    const telefonePattern = '(99) 9999-9999';
    const celPhonePattern = '(99) 99999-9999';
    const numOnly = onlyNumsString(value);
    if (value) {
        return numOnly.length > 10
            ? vanillaMasker.toPattern(numOnly, celPhonePattern)
            : vanillaMasker.toPattern(numOnly, telefonePattern);
    }
    return null;
};

export const telefoneMascarado = (value) => {
    const telefonePattern = '(99) 9999-XXXX';
    const celPhonePattern = '(99) 99999-XXXX';
    const numOnly = onlyNumsAndXString(value);
    if (value) {
        return numOnly.length > 10
            ? vanillaMasker.toPattern(numOnly, celPhonePattern)
            : vanillaMasker.toPattern(numOnly, telefonePattern);
    }
    return null;
};

export const convertStrToHtml = (str) => {
    const regHtml = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;

    if (!regHtml.test(str)) return str;

    const node = <div dangerouslySetInnerHTML={{ __html: str }} />;

    return node;
};
