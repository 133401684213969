/* eslint-disable import/no-named-as-default */
// #region Imports

import Box from '@material-ui/core/Box';
import React, { useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import ScreenLoader from '../../containers/ScreenLoader';
import ENDPOINTS from '../../consts/endpoints';
import { getParameterByName } from '../../library/get-data';
import api from '../../library/api';

// #endregion

const LoginOAuthBBApp = ({ history }) => {
    const handleLoader = useRef();

    const handleToken = useCallback(async () => {
        const token = getParameterByName('tokenbb');

        if (token) {
            handleLoader.current.handleControl();

            await api
                .get(ENDPOINTS.LOGIN_BB_APP(token), { noAuthorization: true })
                .then(({ data }) => {
                    if (data.status === 'OK') {
                        const URI = ENDPOINTS.PORTAL_BB_SEGUROS(
                            encodeURI(data.resultado?.token),
                        );
                        window.location.href = URI;
                    }
                })
                .catch(() => {
                    history.push('/home');
                });
        } else {
            history.push('/home');
        }
    }, [history]);

    useEffect(() => {
        handleToken();
    }, [handleToken]);

    return (
        <>
            <Box height="100vh" width="100vw" bgcolor="#fae128" />
            <ScreenLoader ref={handleLoader} />
        </>
    );
};

export default withRouter(LoginOAuthBBApp);
