/* eslint-disable no-shadow */
import React from 'react';
import { Grid, Paper, Radio, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styles';
import CustomButton from '../CustomButton/CustomButton';
import * as session from '../../utils/session';
import ReactGTM from '../../utils/gtm';

export const TIPO_ENVIO = {
    EMAIL: 'email',
    SMS: 'sms',
};

export default function SelectOption({
    email,
    celular,
    onSubmitSelectCard,
    isPrimeiroAcesso,
}) {
    const classes = useStyles({ isPrimeiroAcesso });
    const [selectedCard, setSelectedCard] = React.useState(
        celular ? null : 'email',
    );

    const isChabot = session.getChatbot();
    const tagId = isChabot
        ? 'continuar-acesso-whats-001'
        : 'continuar-acesso-001';

    const handleSelectCard = (value) => {
        if (value === selectedCard && celular) {
            setSelectedCard(null);
        } else {
            setSelectedCard(value);
        }

        //* * Google Analytics */
        ReactGTM.event({
            event: 'auto.event',
            ev_action: 'selecionou:receber-codigo',
            ev_label: value,
        });
    };

    const Option = ({ tipoEnvio, data, handleSelectCard, selectedCard }) => {
        const selected = selectedCard === tipoEnvio;

        const text =
            tipoEnvio === TIPO_ENVIO.EMAIL
                ? 'Receber por e-mail:'
                : 'Receber por SMS:';
        return (
            <Grid
                container
                justifyContent="center"
                className={classes.container}
            >
                <Grid
                    item
                    component={Paper}
                    square
                    elevation={0}
                    className={clsx(classes.root, selected && classes.selected)}
                    onClick={handleSelectCard}
                >
                    <Grid
                        container
                        justifyContent="flex-start"
                        alignContent="center"
                    >
                        <Grid container item xs={2} alignContent="flex-start">
                            {celular && (
                                <Radio
                                    style={{ padding: '0 9px' }}
                                    color="primary"
                                    checked={selected}
                                />
                            )}
                        </Grid>

                        <Grid
                            xs={10}
                            container
                            item
                            justifyContent="flex-start"
                        >
                            <Grid
                                xs={12}
                                container
                                item
                                justifyContent="flex-start"
                            >
                                <Typography
                                    className={clsx(
                                        classes.titleCard,
                                        selected && classes.selected,
                                    )}
                                >
                                    {text}
                                </Typography>
                            </Grid>
                            <Grid
                                xs={12}
                                container
                                item
                                justifyContent="flex-start"
                            >
                                <Typography
                                    title={data}
                                    className={classes.divData}
                                >
                                    {data}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            <Option
                tipoEnvio="email"
                data={email}
                handleSelectCard={() => handleSelectCard('email')}
                selectedCard={selectedCard}
            />

            {celular && (
                <Option
                    tipoEnvio="sms"
                    data={celular}
                    handleSelectCard={() => handleSelectCard('sms')}
                    selectedCard={selectedCard}
                />
            )}
            <Grid container>
                <CustomButton
                    content={isPrimeiroAcesso ? 'CONTINUAR' : 'Enviar'}
                    className={classes.button}
                    onClickCallBack={() => onSubmitSelectCard(selectedCard)}
                    disabled={Boolean(!selectedCard)}
                    gtm={{
                        id: tagId,
                        'data-gtm-name': 'continuar',
                    }}
                />
            </Grid>
        </>
    );
}
