/* eslint-disable no-shadow */
/* eslint-disable import/no-named-as-default */
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import DoubleCardIcon from '../../../assets/icons/double-card-icon-blue.svg';
import MonitorIcon from '../../../assets/icons/monitor-icon-blue.svg';
import CustomCarousel from '../../../components/CustomCarousel';
import {
    ACCESS_PORTAL_ENUM,
    ACCESS_PORTAL_TEXT,
} from '../../../consts/text/form-login';
import generateUUID from '../../../library/authorization';
import * as session from '../../../utils/session';
import CardAccess from '../CardAccess';
import { opinHomePage } from '../const';
import { useStyles } from './styles';

export const PortalAccessCards = ({ cmdAuth }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('1210'));
    const [uuid, setUuid] = React.useState();

    const { redirectURL, responseType, clientId, scope, authURL } =
        opinHomePage;

    const hrefURL = `${authURL}?redirect_uri=${redirectURL}&response_type=${responseType}&client_id=${clientId}&scope=${scope}&state=${uuid}`;

    const setInfo = useCallback(() => {
        setUuid(localStorage.getItem('UUID'));
        if (!uuid) {
            localStorage.setItem('UUID', generateUUID());
            localStorage.setItem('jti', cmdAuth?.authenticateCommand.jti);
            localStorage.setItem('logoURL', cmdAuth?.tpp.logoUrl);
        }
    }, [cmdAuth, uuid]);

    useEffect(() => {
        const uuid = localStorage.getItem('UUID');
        if (!uuid) {
            localStorage.setItem('UUID', generateUUID());
        }
        session.clearUserName();
        session.clearUserNamePrimeiroAcesso();
        session.clearDataNascPrimeiroAcesso();
        setInfo();
    }, [setInfo]);

    const handleCorrentista = useCallback(() => {
        localStorage.setItem('type_account', 'correntista');
    }, []);

    const portalItens = useMemo(
        () => [
            {
                id: ACCESS_PORTAL_ENUM.PORTAL_CORRENTISTA,
                title: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_TITLE,
                description: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_DESCRIPTION,
                btnText: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_ICON_TEXT,
                icon: DoubleCardIcon,
                href: hrefURL,
                callback: handleCorrentista,
            },
            {
                id: ACCESS_PORTAL_ENUM.PORTAL_NAO_CORRENTISTA,
                title: ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_TITLE,
                description:
                    ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_DESCRIPTION,
                btnText: ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_ICON_TEXT,
                icon: MonitorIcon,
                showLoginForm: true,
            },
        ],
        [handleCorrentista, hrefURL],
    );

    return (
        <Grid container>
            <Grid
                justifyContent="center"
                className={classes.gridContainerPortalAccess}
                container
            >
                <Grid
                    className={classes.gridMaxWidth}
                    justifyContent="space-around"
                    container
                >
                    {isMd ? (
                        <CustomCarousel
                            itemsLenght={portalItens.length}
                            showArrows={false}
                            showDots
                        >
                            {portalItens.map((ele) => (
                                <CardAccess key={ele.id} {...ele} />
                            ))}
                        </CustomCarousel>
                    ) : (
                        <>
                            {portalItens.map((ele) => (
                                <CardAccess key={ele.id} {...ele} />
                            ))}
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
