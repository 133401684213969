import React from 'react';

export const REDEFINIR_SENHA_LABELS = {
    TITLE: 'Código de acesso',
    MENSAGEM_CODIGO_ACESSO: (dadoMascarado) =>
        `Um código de acesso foi enviado para ${dadoMascarado}`,
    MENSAGEM_PRIMEIRO_ACESSO: (opcaoEnvio) =>
        `Digite o código de acesso enviado por ${
            opcaoEnvio === 'EMAIL' ? 'e-mail' : 'SMS'
        } e crie sua senha: `,
    CODIGO_DE_ACESSO: 'Código de acesso*',
    NOVA_SENHA: 'Nova senha*',
    CONFIRMAR_SENHA: 'Confirmar senha*',
    ORIENTACOES: (
        <>
            <span>Orientações de senha:</span>
            <br />• Sua senha deve conter números, letras e caracteres
            especiais. <br />
            • Use letras maiúsculas e minúsculas. <br />• Conter no mínimo 8
            dígitos.
        </>
    ),
    BOTAO: 'SALVAR',
};

export const FORM_REDEFINIR_SENHA_FIELDS = {
    CODIGO_DE_ACESSO: 'codigoAcesso',
    NOVA_SENHA: 'senha',
    CONFIRMAR_SENHA: 'confirmacaoSenha',
};
