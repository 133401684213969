import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    slide: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        overflowX: 'scroll',
        flexWrap: 'noWrap',
        width: '100%',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': { height: '1px' },
    },

    dots: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        width: ({ isBenefits }) =>
            isBenefits && !theme.breakpoints.down('sm') ? 'auto' : '100%',
        transition: 'active 2s ease-in-out',
        '& svg': {
            color: theme.appColors.dotsLightGray,
            fontSize: 23,
        },
    },

    dot: {
        width: 9,
        height: 10,
        borderRadius: '50%',
        cursor: 'pointer',
        margin: '0 5px',
        backgroundColor: theme.appColors.dotsLightGray,
        transition: 'width 0.1s ease-in-out',
    },

    active: {
        backgroundColor: theme.appColors.dotsDarkGray,
        width: 18,
        height: 10,
        borderRadius: '5px',
    },

    box: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: 'fit-content',
        margin: theme.spacing(0, 2),
    },
}));
