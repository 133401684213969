/* eslint-disable import/no-named-as-default */
// #region Imports

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Button from '@material-ui/core/Button';
import ENDPOINTS from '../../../../consts/endpoints';

// #endregion

const FormSubmitSuccess = ({ token }) => {
    return (
        <Box display="flex" flexDirection="column" paddingY="2.4em">
            <Box display="flex" justifyContent="center" paddingY="10px">
                <Typography variant="h4">
                    Sua solicitação foi cadastrada com sucesso.
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                paddingY="10px"
                textAlign="center"
            >
                <Typography variant="subtitle2">
                    Caso você tenha contratado o produto BB Seguro Auto,
                    recomendamos que também faça sua <br />
                    solicitação pelo e-mail&nbsp;
                    <a href="mailto:protecaodedados@mapfre.com.br">
                        protecaodedados@mapfre.com.br
                    </a>
                    .
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" paddingY="10px">
                <Typography variant="subtitle2">
                    Sua resposta chegará em até 15 dias.
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" paddingY="10px">
                <Button
                    variant="contained"
                    color="primary"
                    component="button"
                    size="large"
                    onClick={() =>
                        window.location.replace(
                            ENDPOINTS.PORTAL_BB_SEGUROS(token),
                        )
                    }
                >
                    Fechar
                </Button>
            </Box>
        </Box>
    );
};

export default FormSubmitSuccess;
