import { FONTS } from '../fonts/fonts';
import { appColors } from '../../AppTheme';

const FONT_STYLES = {
    TITLE: (titleFontSize = '24px', titleColor = appColors.gray) => ({
        fontWeight: '400',
        color: titleColor,
        fontSize: titleFontSize,
        ...FONTS.ArialMTBold,
    }),
    SUBTITLE: (subTitleFontSize = '16px', subTitleColor = appColors.gray) => ({
        fontWeight: 'bold',
        color: subTitleColor,
        fontSize: subTitleFontSize,
        ...FONTS.ArialMTLight,
    }),
};

export default FONT_STYLES;
