export const FORM_LOGIN_FIELDS = {
    FIELD_EMAIL: 'email',
    FIELD_SENHA: 'password',
};

export const FORM_LOGIN_LABELS = {
    NAME_EMAIL: 'Email',
    LABEL_EMAIL: 'E-mail',
    LABEL_EMAIL_OBRIGATORIO: 'E-mail*',
    NAME_SENHA: 'SENHA',
    LABEL_SENHA: 'Senha',
    BTN: 'ACESSAR O PORTAL',
    BTN_LOGINBB: 'ACESSE COM SUA SENHA BB',
    BTN_LOGIN_ASSIST: 'ACESSE COMO ASSISTÊNCIA',
    BTN_ACESSAR: 'ACESSAR',
};

export const ACCESS_PORTAL_TEXT = {
    HOME_TITLE: 'Área do cliente BB Seguros',
    HOME_SUB_TITLE1:
        'Consulte as informações sobre suas apólices, acione seu seguro ou solicite assistências.',
    HOME_SUB_TITLE2:
        'Clientes Agro: Acesse a Plataforma de Sensoriamento para monitorar sua lavoura.',
    PORTAL_CORRENTISTA_TITLE: 'Para correntistas do Banco do Brasil',
    PORTAL_CORRENTISTA_DESCRIPTION:
        'Você será redirecionado à outra página para inserir seus dados bancários e acessar a Área do Cliente.',
    PORTAL_CORRENTISTA_ICON_TEXT: 'Acesse usando sua conta do BB',
    PORTAL_NAO_CORRENTISTA_TITLE: 'Para não correntistas do Banco do Brasil',
    PORTAL_NAO_CORRENTISTA_DESCRIPTION:
        'Preencha os seus dados para acessar a Área do Cliente.',
    PORTAL_NAO_CORRENTISTA_ICON_TEXT: 'Acesse usando login e senha',
    SENSORIAMENTO_TITLE: 'Clientes Agro',
    SENSORIAMENTO_DESCRIPTION:
        'Você será redirecionado à outra página para inserir seus dados e acessar a plataforma de monitoramento.',
    SENSORIAMENTO_ICON_TEXT: 'Acesse a plataforma Sensoriamento Remoto',
};

export const ACCESS_PORTAL_ENUM = {
    PORTAL_CORRENTISTA: 'CORRENTISTA',
    PORTAL_NAO_CORRENTISTA: 'NAO_CORRENTISTA',
    SENSORIAMENTO: 'SENSORIAMENTO',
};

export const PORTAL_LOGIN_TYPE = {
    CORRENTISTA: '1',
    NAO_CORRENTISTA: '2',
};

export const LOGIN_ORIGIN = {
    CHATBOT: 'chatbot',
};
