import { Divider, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PortalAccessCards } from '../../containers/PortalAccessCards';
import { generateUUID } from '../../library/authorization';
import { AutenticationActions } from '../../store/ducks/autentication';
import * as session from '../../utils/session';
import { useStyles } from './styles';
import {
    ACCESS_PORTAL_TEXT,
    LOGIN_ORIGIN,
    PORTAL_LOGIN_TYPE,
} from '../../consts/text/form-login';

/**
 * @Login Página de Login da aplicação
 * @CriadoEm 14/01/2022
 */
export default function LoginPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoginChatbot, setIsLoginChatbot] = useState();

    useEffect(() => {
        const uuid = localStorage.getItem('UUID');

        if (!uuid) {
            localStorage.setItem('UUID', generateUUID());
        }

        (async () => {
            dispatch(AutenticationActions.getURLRequest());
        })();
    }, [dispatch]);

    useEffect(() => {
        session.clearUserName();
        session.clearUserNamePrimeiroAcesso();
        session.clearDataNascPrimeiroAcesso();
    }, []);

    useEffect(() => {
        const params = window?.location?.pathname.split('/');

        const origem = params[1];
        const hash = params[2];
        let chatbot = params[3];

        if (origem === LOGIN_ORIGIN.CHATBOT) {
            session.setChatbot(chatbot);
            session.setHashChatbot(hash);
        } else {
            chatbot = session.getChatbot();
        }

        setIsLoginChatbot(
            chatbot === PORTAL_LOGIN_TYPE.CORRENTISTA ||
                chatbot === PORTAL_LOGIN_TYPE.NAO_CORRENTISTA,
        );
    }, []);

    return (
        <Grid container justifyContent="center">
            <Grid
                container
                justifyContent="center"
                className={classes.outerGridContainer}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.gridContainer}
                >
                    <Grid container direction="column">
                        <Typography className={classes.title}>
                            {ACCESS_PORTAL_TEXT.HOME_TITLE}
                        </Typography>
                        <Divider className={classes.underlineText} />
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        className={classes.gridContainerDescription}
                    >
                        {!isLoginChatbot && (
                            <>
                                <Typography className={classes.description}>
                                    {ACCESS_PORTAL_TEXT.HOME_SUB_TITLE1}
                                </Typography>
                                <Typography className={classes.description}>
                                    {ACCESS_PORTAL_TEXT.HOME_SUB_TITLE2}
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>

                <PortalAccessCards />
            </Grid>
        </Grid>
    );
}
