// #region Imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FONT_STYLES from "../../assets/css/font";
// #endregion

export const useStyles = () => {
    const styles = makeStyles((theme) =>
        createStyles({
            logo: {
                height: '60px',
            },
            text: {
                textAlign: 'center',
                ...FONT_STYLES.SUBTITLE('14px', theme.appColors.lighterBlue),
            },
            appBar: {
                backgroundColor: theme.appColors.alternativeGray,
            },
            tab: {
                fontWeight: 600,
                textTransform: 'none',
            },
        }),
    );

    return styles();
};

export default useStyles;
