/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-bitwise */
import { getParameterByName } from './utils';

export const TOKEN_SENSORIAMENTO_REMOTO =
    'eyKicGciNhKIUzH0OiJ9/dxJqdFjhOiIyLEEwMU@xMTU1OBIsIlmidCI7LUU2OEfxODYyOxwiaYO{Ijoh`IR0cIL7Ly9uc3JpbFTtZGV3MVNsZB4hcmF{`VxzZVbtY29uMlJyIhvhY29ocll0b{qocm90bIMiOmrhQkI{LBJdLBK0c2VxclFtZRH7Im1wXllsZULtMCJ8/nui1YgpHFKN{O7GWDLiTuOtGo3r0K[nANZfScf6_L,1';

export const TOKEN_USUARIO_SENSORIAMENTO =
    'eyKicGciNhKIUzH0OiJ9/dxJqdFjhOiIyLEEwMU@xMTU1OBIsIlmidCI7LUYwMEP{OTM5OxwiaYO{Ijoh`IR0cIL7Ly9ibFlnYYSmd2F4MlNsZB4hcmF{`VxzZVbtY29uMlJyIhvhY3BlHkoiMkfxMzUxNEI5NkfhLCJjeD5hc3LhOiIvLR8wMR9yOTkvHhwiblGuZSI7HjFsZYiibmRmbhBGZYKtYW5j[YMgQoKiZ2Ftv7dhIo1/KgvuC5cI2K3odmdV,j2C5gHqLOPw15kAqI7WZtvJD6Y';

export const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (crypto?.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
    );
};

export const authBBSeg = () => {
    const code = getParameterByName('code');
    const state = getParameterByName('state');

    if (code && state) {
        const uuid = localStorage.getItem('UUID');
        return String(uuid) === String(state) ? String(code) : null;
    }
};

export default generateUUID;
