import { criptoToAES, decriptFromAES } from './criptografia';

/* KEYS */
const SESSION_ID = 'nmIdSessao';
const TRANSACTION_ID = 'nmIdTransacao';
const USER_NAME = 'userName';
const USER_NAME_PRIMEIRO_ACESSO = 'userNamePrimeiroAcesso';
const DATA_PRIMEIRO_ACESSO = 'dataPrimeiroAcesso';
const CHATBOT = 'chatbot';
const HASH_CHATBOT = 'hashChatbot';

export const get = (key) => window.sessionStorage.getItem(key);

export const set = (key, value) => window.sessionStorage.setItem(key, value);

export const clear = () => window.sessionStorage.clear();

export const isSession = () => Boolean(get(SESSION_ID));

export const getSession = () => get(SESSION_ID);

export const setSession = (nmIdSessao) => set(SESSION_ID, nmIdSessao);

export const setTransaction = (nmIdTransacao) => {
    set(TRANSACTION_ID, nmIdTransacao);
};

export const getTransaction = () => get(TRANSACTION_ID);

export const getUserName = () => {
    const userName = get(USER_NAME);
    return decriptFromAES(userName);
};

export const setUserName = (userName) => {
    set(USER_NAME, criptoToAES(userName));
};

export const getUserNamePrimeiroAcesso = () => {
    const userName = get(USER_NAME_PRIMEIRO_ACESSO);
    return decriptFromAES(userName);
};

export const setUserNamePrimeiroAcesso = (userName) => {
    set(USER_NAME_PRIMEIRO_ACESSO, criptoToAES(userName));
};

export const getChatbot = () => get(CHATBOT);

export const setChatbot = (accountType) => {
    set(CHATBOT, accountType);
};

export const getHashChatbot = () => get(HASH_CHATBOT);

export const setHashChatbot = (hash) => {
    set(HASH_CHATBOT, hash);
};

export const clearUserName = () => {
    window.sessionStorage.removeItem(USER_NAME);
};

export const clearUserNamePrimeiroAcesso = () => {
    window.sessionStorage.removeItem(USER_NAME_PRIMEIRO_ACESSO);
};

export const getDataPrimeiroAcesso = () => {
    const data = get(DATA_PRIMEIRO_ACESSO);
    return decriptFromAES(data);
};

export const setDataNascPrimeiroAcesso = (data) => {
    set(DATA_PRIMEIRO_ACESSO, criptoToAES(data));
};

export const clearDataNascPrimeiroAcesso = () => {
    window.sessionStorage.removeItem(DATA_PRIMEIRO_ACESSO);
};
