import {
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FORM_DADOS_PESSOAIS as fpText } from '../../consts/text';
import PRIMEIRO_ACESSO from '../../consts/text/primeiro-acesso';
import FormPrimeiroAcesso from '../../containers/FormPrimeiroAcesso';
import FormPrimeiroAcessoPJ from '../../containers/FormPrimeiroAcessoPJ';
import UsuarioValidadoPage from '../primeiroAcessoUsuarioValidado';
import { useStyles } from './styles';
import ReactGTM from '../../utils/gtm';

export default function PrimeiroAcesso() {
    const classes = useStyles();
    const history = useHistory();
    const { state } = history.location;

    const [tipoPessoa, setTipoPessoa] = React.useState('pf');

    const handleChangeCheckBox = ({ target }) => {
        setTipoPessoa(target.value);
    };

    const getForm = () => {
        switch (tipoPessoa) {
            case 'pf':
                return <FormPrimeiroAcesso />;
            case 'pj':
                return <FormPrimeiroAcessoPJ />;
            default:
                return null;
        }
    };

    useEffect(() => {
        ReactGTM.customData('area-cliente-cadastro');
    }, []);

    if (state?.isValidated) {
        return <UsuarioValidadoPage />;
    }
    return (
        <Grid
            container
            className={classes.root}
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                sm={7}
                md={6}
                xl={4}
                square
                elevation={0}
                component={Paper}
                className={classes.containerGrid}
            >
                <Grid item container justifyContent="center">
                    <Grid
                        item
                        container
                        justifyContent="flex-start"
                        direction="row"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid
                            item
                            container
                            justifyContent="center"
                            spacing={2}
                            direction="row"
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h2"
                                    className={classes.titulo}
                                >
                                    {PRIMEIRO_ACESSO.TITLE}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h3"
                                    className={classes.subTitulo}
                                >
                                    {PRIMEIRO_ACESSO.DESCRIPTION}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        value={tipoPessoa}
                                        onChange={handleChangeCheckBox}
                                        className={classes.radioGroup}
                                    >
                                        <FormControlLabel
                                            value="pf"
                                            label={
                                                <Typography
                                                    className={
                                                        classes.radioText
                                                    }
                                                >
                                                    {fpText.PESSOA_FISICA}
                                                </Typography>
                                            }
                                            control={<Radio size="small" />}
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="pj"
                                            label={
                                                <Typography
                                                    className={
                                                        classes.radioText
                                                    }
                                                >
                                                    {fpText.PESSOA_JURIDICA}
                                                </Typography>
                                            }
                                            control={<Radio size="small" />}
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {getForm()}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
