/* eslint-disable import/no-named-as-default */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-await */
// #region Imports

import Box from '@material-ui/core/Box';
import React, { useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import ScreenLoader from '../../containers/ScreenLoader';
import api from '../../library/api';
import { authBBSeg } from '../../library/authorization';
import ENDPOINTS from '../../consts/endpoints';
import { getParameterByName } from '../../library/get-data';
// #endregion

const LoginOAuthBB = ({ history }) => {
    const handleLoader = useRef();

    const handleToken = useCallback(async () => {
        let token = getParameterByName('token');

        if (!token) {
            const code = authBBSeg();

            handleLoader.current.handleControl();

            if (code) {
                token = await getToken(code);
            } else {
                history.push('/home');
            }
        }

        if (token) {
            localStorage.clear();

            window.location.href = ENDPOINTS.PORTAL_BB_SEGUROS(
                encodeURIComponent(token),
            );
        }
    }, [history]);

    useEffect(() => {
        handleToken();
    }, [handleToken]);

    const getToken = async (code) => {
        const url = ENDPOINTS.GET_TOKEN(code);

        return await api
            .get(url)
            .then((response) => {
                return response.data.token;
            })
            .catch((error) => {
                return error;
            });
    };

    return (
        <>
            <Box height="100vh" width="100vw" bgcolor="#fae128" />
            <ScreenLoader ref={handleLoader} />
        </>
    );
};

export default withRouter(LoginOAuthBB);
