import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ConfirmacaoSegurancaIcon } from '../../assets/icons/iconConfirmacaoSeguranca.svg';
import ROUTES_NAME from '../../consts/routes-name';
import { CONFIRMACAO_TXTS } from '../../consts/text/confirmacao-seguranca';
import FormConfirmacaoSeguranca from '../../containers/FormConfirmacaoSeguranca/index';
import * as session from '../../utils/session';
import { useStyles } from './styles';
import ReactGTM from '../../utils/gtm';

export default function ValidacaoCodigoPrimeiroAcesso() {
    const classes = useStyles();
    const history = useHistory();

    const { state } = history.location;

    const isChatbot = session.getChatbot();
    const tagId = isChatbot ? 'nao-recebeu-whats-001' : 'nao-recebeu-001';

    useEffect(() => {
        const userNamePrimeiroAcesso = session.getUserNamePrimeiroAcesso();
        if (!userNamePrimeiroAcesso) {
            history.push(ROUTES_NAME.HOME);
        }
    }, [history]);

    useEffect(() => {
        ReactGTM.customData('crie-sua-senha');
    }, []);

    return (
        <Grid
            className={classes.root}
            container
            alignItems="center"
            justifyContent="center"
        >
            <Grid
                xs={12}
                sm={7}
                md={5}
                xl={3}
                item
                elevation={0}
                square
                className={classes.containerGrid}
                component={Paper}
                spacing={2}
                direction="row"
                container
            >
                <ConfirmacaoSegurancaIcon />
                <Grid xs={12} item>
                    <Typography className={classes.titulo} variant="h2">
                        {CONFIRMACAO_TXTS.TITLE()}
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Typography className={classes.subTitulo} variant="h3">
                        {CONFIRMACAO_TXTS.DESCRIPTION(state?.tipoEnvio)}
                    </Typography>
                </Grid>

                <FormConfirmacaoSeguranca isPrimeiroAcesso />
                <Grid xs={12} item>
                    <Typography
                        className={classes.link}
                        onClick={() =>
                            history.push(
                                ROUTES_NAME.SELECAO_ENVIO_CODIGO_PRIMEIRO_ACESSO,
                            )
                        }
                        component="span"
                        data-gtm-type="click"
                        data-gtm-clicktype="button"
                        id={tagId}
                        data-gtm-name="clique-aqui"
                    >
                        {CONFIRMACAO_TXTS.NAO_RECEBI}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
