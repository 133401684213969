import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconErroPrimeiroAcesso } from '../../assets/icons/iconErroPrimeiroAcesso.svg';
import { AutenticationActions } from '../../store/ducks/autentication';
import CustomButton from '../CustomButton/CustomButton';
import CustomLoading from '../CustomLoading/CustomLoading';
import CustomModal from '../CustomModal/CustomModal';
import CustomBottomSheet from '../CustomBottomSheet/index';
import { useStyles } from './styles';
import history from '../../utils/history';
import ROUTES_NAME from '../../consts/routes-name';
import ReactGTM from '../../utils/gtm';
import * as session from '../../utils/session';

const { setAutenticateMessage, setErrorDescription } = AutenticationActions;

export default function ModalErroPrimeiroAcesso() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));

    const store = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const [showModal, setShowModal] = useState(false);

    const {
        autenticacao: { message, errorDescription, loading },
    } = store;

    const handleCloseModal = (redirect = true) => {
        //* * Google Analytics */
        ReactGTM.event({
            event: 'modal',
            action: 'open',
            name: 'erro-primeiro-acesso',
        });
        setShowModal(false);

        if (message) {
            dispatch(setAutenticateMessage(null));
            dispatch(setErrorDescription(null));
        }
        if (redirect) {
            if (session.getChatbot()) history.push(ROUTES_NAME.HOME);
            else history.push(ROUTES_NAME.LOGIN);
        }
    };

    useEffect(() => {
        const hasErro = Boolean(message);
        //* * Google Analytics */
        ReactGTM.event({
            event: 'modal',
            action: 'open',
            name: 'erro-primeiro-acesso',
        });
        setShowModal(hasErro);
    }, [message]);

    return (
        <>
            {loading && <CustomLoading open={loading} />}
            {isMobile ? (
                <CustomBottomSheet
                    open={showModal}
                    handleClose={() => handleCloseModal(false)}
                    title={message}
                    subTitle={errorDescription}
                    onClickButton={handleCloseModal}
                    buttonText="VOLTAR PARA LOGIN"
                />
            ) : (
                <CustomModal
                    open={showModal}
                    title={message}
                    icon={<IconErroPrimeiroAcesso />}
                    className={classes.modal}
                    body={
                        <Grid container alignItems="center" direction="column">
                            {errorDescription && (
                                <Typography className={classes.message}>
                                    {errorDescription}
                                </Typography>
                            )}
                            <CustomButton
                                content="VOLTAR PARA LOGIN"
                                onClickCallBack={handleCloseModal}
                            />
                        </Grid>
                    }
                    handleClose={() => handleCloseModal(false)}
                />
            )}
        </>
    );
}
