import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import FormRedefinirSenha from '../../containers/FormRedefinirSenha';
import { useStyles } from './styles';
import ReactGTM from '../../utils/gtm';

export default function RedefinirSenhaPage() {
    const classes = useStyles();

    useEffect(() => {
        ReactGTM.customData('codigo-acesso');
    }, []);

    return (
        <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.container}
        >
            <Grid item container xs={12} className={classes.formContainer}>
                <FormRedefinirSenha />
            </Grid>
        </Grid>
    );
}
