/* eslint no-template-curly-in-string: 0 */
// #region Imports

import * as yup from 'yup';

// #endregion

export function emptyStringToUndefined(value, originalValue) {
    if (
        (typeof originalValue === 'string' && originalValue === '') ||
        originalValue === null
    ) {
        return undefined;
    }
    return value;
}

yup.setLocale({
    mixed: { required: 'O campo é obrigatório' },
    string: {
        min: 'O campo deve ter no mínimo ${min} caracteres',
        max: 'O campo deve ter no máximo ${max} caracteres',
        email: 'E-mail inválido',
    },
    number: {
        min: 'O campo deve ter o valor maior ou igual a ${min}',
        max: 'O campo deve ter o valor menor ou igual a ${max}',
    },
});

export default yup;
