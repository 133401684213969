/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from '@material-ui/core/Box';
import axios from 'axios';
import React, { useCallback, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import ScreenLoader from '../../../containers/ScreenLoader';
import { getParameterByName } from '../../../library/get-data';
import { loginOAuthBBOpinPage } from '../const';

const { apiUrl, bbCorrentistaURL, bbNaoCorrentistaURL } = loginOAuthBBOpinPage;

const LoginOAuthBBOpin = ({ history }) => {
    const handleLoader = useRef();
    const code = getParameterByName('code');
    const token = getParameterByName('token');
    const type_account = localStorage.getItem('type_account');
    const commandId = localStorage.getItem('commandId');
    const jti = localStorage.getItem('jti');

    const loginBBAuthBackend = async () => {
        const url = `${apiUrl}`;
        await axios
            .post(
                url,
                {
                    userName: process.env.REACT_APP_OPIN_USERNAME,
                    password: process.env.REACT_APP_OPIN_PASSWORD,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            .then((res) => {
                localStorage.setItem('tokenBBAuth', res.data.token);
            });
    };

    const redirectToOpin = useCallback(async (res) => {
        handleLoader.current.handleControl();
        if (res.command === 'error') {
            window.location.href = `${res.errorCommand.redirect.redirectTo}`;
        }
        if (res.command === 'completed') {
            window.location.href = `${res.completedCommand.redirect.redirectTo}`;
        }
    }, []);

    const loginCorrentista = useCallback(async () => {
        if (!code) {
            handleLoader.current.handleControl();
            history.push(`/authconsent#${commandId}`);
        } else if (type_account === 'correntista') {
            const url = `${bbCorrentistaURL}?commandId=${commandId}&jti=${jti}&bbOauthCode=${code}`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `${localStorage.getItem('tokenBBAuth')}`,
                    },
                })
                .then((response) => {
                    redirectToOpin(response.data.resultado);
                });
        }
    }, [history]);

    const loginNaoCorrentista = useCallback(async () => {
        if (!token) {
            handleLoader.current.handleControl();
            history.push(`/authconsent#${commandId}`);
        } else if (type_account === 'naoCorrentista') {
            const url = `${bbNaoCorrentistaURL}?commandId=${commandId}&jti=${jti}&jwtTokenZuul=${token}`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `${localStorage.getItem('tokenBBAuth')}`,
                    },
                })
                .then((response) => {
                    redirectToOpin(response.data.resultado);
                });
        }
    }, [history]);

    useEffect(() => {
        code && loginBBAuthBackend().then(() => loginCorrentista());
        token && loginBBAuthBackend().then(() => loginNaoCorrentista());
    }, []);

    return (
        <>
            <Box height="100vh" width="100vw" bgcolor="#fae128" />
            <ScreenLoader ref={handleLoader} />
        </>
    );
};

export default withRouter(LoginOAuthBBOpin);
