// #region Imports

import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import Header from './components/Header';

import AppTheme from './AppTheme';
import AppRoutes from './AppRoutes';
import Footer from './components/Footer';
import store from './store';

import './assets/css/global.css';
import LoadingErrorMessage from './components/LoadingErrorMessage/LoadingErrorMessage';

// #endregion

function App() {
    return (
        <Fragment>
            <Provider store={store}>
                <BrowserRouter>
                    <ThemeProvider theme={AppTheme}>
                        <SnackbarProvider
                            maxSnack={2}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Header />
                            <AppRoutes />
                            <Footer />
                            <LoadingErrorMessage />
                        </SnackbarProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </Provider>
        </Fragment>
    );
}

export default App;
