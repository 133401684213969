/* eslint-disable no-shadow */
import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ConfirmacaoSegurancaIcon } from '../../assets/icons/iconConfirmacaoSeguranca.svg';
import SelectOption from '../../components/SelectOption';
import ROUTES_NAME from '../../consts/routes-name';
import { CONFIRMACAO_TXTS } from '../../consts/text/confirmacao-seguranca';
import FormConfirmacaoSeguranca from '../../containers/FormConfirmacaoSeguranca/index';
import { AutenticationActions } from '../../store/ducks/autentication';
import * as session from '../../utils/session';
import { telefoneMascarado } from '../../utils/normalizers/normalizers';
import { useStyles } from './styles';
import ReactGTM from '../../utils/gtm';

export default function ConfirmacaoSeguranca() {
    const history = useHistory();
    const classes = useStyles();

    const [hasCodigo, setHasCodigo] = React.useState(false);

    const { autenticacao, dadosMascarados } = useSelector((state) => ({
        autenticacao: state.autenticacao,
        dadosMascarados: state.autenticacao?.dadosMascarados,
    }));

    const dispatch = useDispatch();
    const userName = session.getUserName();
    const userNamePrimeiroAcesso = session.getUserNamePrimeiroAcesso();

    const handleSubmitSelectCard = (value) => {
        const { state } = history.location;
        dispatch(
            AutenticationActions.postReenviarTokenRequest({
                userName,
                sms: value === 'sms',
                esqueciSenha: state?.esqueciSenha,
                dadoMascarado:
                    value === 'sms'
                        ? telefoneMascarado(dadosMascarados?.telefone)
                        : dadosMascarados?.emailAddr,
            }),
        );
        if (!state?.esqueciSenha) {
            setHasCodigo(true);
        }
    };

    useEffect(() => {
        const { state } = history.location;
        if (!dadosMascarados && (userName || userNamePrimeiroAcesso)) {
            if (state?.isPrimeiroAcesso) {
                setHasCodigo(true);
            } else {
                dispatch(
                    AutenticationActions.getDadosMascaradosRequest({
                        email: userName,
                        isPrimeiroAcesso: false,
                    }),
                );
            }
        }
    }, [dispatch, userName, userNamePrimeiroAcesso, dadosMascarados, history]);

    useEffect(() => {
        const userName = session.getUserName();
        const userNamePrimeiroAcesso = session.getUserNamePrimeiroAcesso();
        if (!userName && !userNamePrimeiroAcesso) {
            history.push(ROUTES_NAME.HOME);
        }
    }, [history]);

    useEffect(() => {
        ReactGTM.customData('crie-sua-senha');
    }, []);

    return (
        <Grid
            container
            className={classes.root}
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                sm={7}
                md={5}
                xl={3}
                square
                elevation={0}
                component={Paper}
                className={classes.containerGrid}
                container
                spacing={2}
                direction="row"
            >
                <ConfirmacaoSegurancaIcon />
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.titulo}>
                        {CONFIRMACAO_TXTS.TITLE()}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.subTitulo}>
                        {CONFIRMACAO_TXTS.DESCRIPTION(
                            autenticacao?.confirmaToken === 'EMAIL'
                                ? 'E-mail'
                                : 'SMS',
                        )}
                    </Typography>
                </Grid>

                {hasCodigo ? (
                    <>
                        <FormConfirmacaoSeguranca />
                        <Grid item xs={12}>
                            <Typography
                                component="span"
                                className={classes.link}
                                onClick={() => setHasCodigo(false)}
                            >
                                {CONFIRMACAO_TXTS.NAO_RECEBI}
                            </Typography>
                        </Grid>
                    </>
                ) : (
                    <SelectOption
                        email={dadosMascarados?.emailAddr}
                        celular={telefoneMascarado(dadosMascarados?.telefone)}
                        onSubmitSelectCard={(value) =>
                            handleSubmitSelectCard(value)
                        }
                    />
                )}
            </Grid>
        </Grid>
    );
}
