/* eslint-disable import/no-extraneous-dependencies */
import { Fade, Grid, Modal } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { detect } from 'detect-browser';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';

/**
 *
 * @CustomModal Componente para exibicao de um modal
 * @CriadoEm 24/01/2022
 */
export default function CustomModal({
    open,
    handleClose,
    body,
    title,
    subTitle,
    icon,
    description,
    actions,
    isCobertura,
    applyMargin,
    modalWidth,
    isResumo,
    modalClasse,
}) {
    const browser = detect()?.name;
    const classes = useStyles({ isCobertura, applyMargin, browser, isResumo });

    let mWidth = { sm: 6 };

    mWidth = modalWidth || mWidth;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
            closeAfterTransition
        >
            <Fade in={open}>
                <Grid
                    item
                    className={clsx(classes.containerModal, modalClasse)}
                    {...mWidth}
                >
                    {handleClose && (
                        <Close
                            className={classes.closeIcon}
                            onClick={handleClose}
                        />
                    )}
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                    >
                        {icon && <div className={classes.icon}>{icon}</div>}
                        {title && <div className={classes.title}>{title}</div>}
                        {subTitle && (
                            <div className={classes.subTitle}>{subTitle}</div>
                        )}
                        {description && (
                            <div className={classes.description}>
                                {description}
                            </div>
                        )}
                    </Grid>
                    {body && (
                        <Grid item xs={12}>
                            {body}
                        </Grid>
                    )}

                    {actions && (
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.actions}
                        >
                            {actions}
                        </Grid>
                    )}
                </Grid>
            </Fade>
        </Modal>
    );
}

/**
 * @prop open {boolean} propriedade para exibir o modal
 * @prop handleClose {func} funcao para fechar o modal
 * @prop title {string} titulo do modal
 * @prop icon {element} ícone do modal
 * @prop subTitle {string} sub-titulo do modal
 * @prop description {string} descricao do modal
 * @prop body {element} elemento com o corpo a ser exibido no modal
 * @prop actions {element} elemento com os possiveis botoes de acao do modal
 */
CustomModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.element,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    actions: PropTypes.element,
};

CustomModal.defaultProps = {
    title: undefined,
    icon: undefined,
    subTitle: undefined,
    description: undefined,
    body: undefined,
    actions: undefined,
    handleClose: undefined,
};
