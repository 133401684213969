/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */

import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { ReactComponent as CheckInput } from '../../assets/icons/check-input-icon.svg';
import { useStyles } from './styles';
/**
 *
 * @CustomMaskedInput Componente para passar propriedades personalizadas ao inputComponent.
 * @CriadoEm 29/04/2020
 */
function CustomMaskedInput({ inputRef, guide, showMask, mask, ...props }) {
    return (
        <MaskedInput
            {...props}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            guide={guide}
            showMask={showMask}
            mask={mask}
        />
    );
}
/**
 * @prop inputRef  {func} função que passa o ref do input.
 * @prop guide {boll} propriedade de quide do imput.
 * @prop showMask {boll} propriedade de exibição de mascara do input.
 * @prop mask {array} propriedade do array de maskara do input.
 */

CustomMaskedInput.propTypes = {
    inputRef: PropTypes.func,
    guide: PropTypes.bool,
    showMask: PropTypes.bool,
    mask: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.array,
        PropTypes.bool,
    ]),
};

CustomMaskedInput.defaultProps = {
    inputRef: () => null,
    guide: false,
    showMask: false,
    mask: false,
};

/**
 *
 * @CustominputForm Componente para exibir os inputs do form.
 * @CriadoEm 29/04/2020
 */

export default function CustomInputForm({
    name,
    errors,
    inputProps,
    customClass,
    fullWidth,
    loading,
    shrink,
    checkValid,
    forceChecked,
    onChangeFlag,
    endAdornment,
    flagCartao,
    onlyLetters,
    onlyNumbers,
    onBlur,
    replaceRegex,
    ...props
}) {
    const classes = useStyles();
    const [check, setCheck] = useState(false);

    const onChangeValidation = (event) => {
        const { value } = event.target;
        props.onChange && props.onChange(value);

        if (replaceRegex) {
            const newValue = value.replace(replaceRegex, '');
            event.target.value = newValue;
            return event;
        }
        if (onlyLetters) {
            const newValue = value.replace(
                /[^aAáÁâÂàÀäÄãÃeEéÉêÊèÈëËiIíÍîÎìÌïÏoOóÓôÔòÒöÖõÕuUúÚûÛùÙüÜcCçÇa-zA-Z\s]+/g,
                '',
            );
            event.target.value = newValue;
            return event;
        }
        if (onlyNumbers) {
            const newValue = value.replace(/[^0-9]+/g, '');
            event.target.value = newValue;
            return event;
        }

        return event;
    };

    const handleChecked = (e) => {
        if (checkValid) {
            setCheck(Boolean(e.target.value));
        }

        if (onBlur) return onBlur(e);
    };

    const renderEndAdorment = () => {
        let endIcon = endAdornment;

        if (loading) {
            endIcon = (
                <CircularProgress className={classes.loading} size={20} />
            );
        } else if (check) {
            endIcon = <CheckInput />;
        }

        if (endIcon) {
            return <InputAdornment position="end">{endIcon}</InputAdornment>;
        }
    };

    useEffect(() => {
        if (forceChecked) {
            setCheck(true);
        }
    }, [forceChecked]);

    if (shrink) {
        props.InputLabelProps = {
            ...props?.InputLabelProps,
            shrink,
        };
    }

    return (
        <TextField
            {...props}
            name={name}
            variant="outlined"
            fullWidth={fullWidth}
            error={!!errors[name]}
            className={clsx(classes.root, customClass || '')}
            helperText={errors[name] && errors[name].message}
            onBlur={handleChecked}
            InputProps={{
                endAdornment: renderEndAdorment(),
                inputComponent: CustomMaskedInput,
                classes: { notchedOutline: classes.notchedOutline },
                inputProps: {
                    ...inputProps,
                },
            }}
            onChange={onChangeValidation}
        />
    );
}
/**
 * @prop errors  {obj} objeto de erros vindo do formulario
 * @prop inputProps {obj} atributos aplicados ao elemento de input
 * @prop customClass {string} propride que tribue valor a uma classe.
 * @prop fullWidth bool determina se a entrada terá tamanho máximo ou não.
 * @prop name {string} propriedade do name que o DatePicker ira receber
 * @prop loading {bool} indica se um componente está em uma estado de carregamento ou não.
 * @prop shrink {bool} movimenta o placeholder do input para a sua borda.
 */

CustomInputForm.propTypes = {
    errors: PropTypes.shape({}),
    inputProps: PropTypes.shape({}),
    customClass: PropTypes.string,
    fullWidth: PropTypes.bool,
    name: PropTypes.string,
    loading: PropTypes.bool,
    shrink: PropTypes.bool,
};

CustomInputForm.defaultProps = {
    errors: {},
    inputProps: {},
    customClass: '',
    name: '',
    fullWidth: true,
    loading: false,
    shrink: undefined,
};
