/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useCallback } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { format, isValid, parseISO } from 'date-fns';
import PropType from 'prop-types';
import ptBRLocale from 'date-fns/locale/pt-BR';
import { useStyles } from './styles';
import { ReactComponent as IconCalendario } from '../../assets/images/iconCalendario.svg';

/**
 *
 * @CustomDatePicker Componente para exibir um DatePicker
 * @CriadoEm 29/04/2020
 */
export default function CustomDatePicker({
    register,
    errors,
    setValueForm,
    triggerValidation,
    formState,
    initialValue,
    name,
    ...props
}) {
    const classes = useStyles();

    const [selectedDate, setSelectedDate] = useState(null);

    const formatDate = (date) => isValid(date) && format(date, 'dd/MM/yyyy');

    const handleDateChange = (date) => {
        const formatedDate = formatDate(date);
        setValueForm(name, formatedDate);

        if (formState.isSubmitted) triggerValidation(name);
        setSelectedDate(date || 'Invalid Date');
    };

    const registerAndSetValue = useCallback(() => {
        register({ name });
    }, [name, register]);

    useEffect(() => registerAndSetValue(), [registerAndSetValue]);

    useEffect(() => {
        if (initialValue) {
            const date = parseISO(initialValue.split('/').reverse().join('-'));
            setSelectedDate(date);
        }
    }, [initialValue]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
            <KeyboardDatePicker
                {...props}
                autoOk
                fullWidth
                value={selectedDate}
                variant="inline"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                error={Boolean(errors && errors[name])}
                helperText={errors && errors[name] && errors[name].message}
                onChange={handleDateChange}
                className={classes.root}
                // InputLabelProps={{
                //     shrink: true,
                // }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                keyboardIcon={<IconCalendario />}
            />
        </MuiPickersUtilsProvider>
    );
}

/**
 * @prop register {func} funcao para registrar o DatePicker ao useForm do react-hooks-form
 * @prop errors {obj} objeto de erros vindo do formulario
 * @prop setValueForm {func} funcao para setar o valor no formulario do react-hooks-form
 * @prop triggerValidation {func} funcao para dar o gatilho de validacao no campo especifico do formulario
 * @prop formState {obj} objeto que contem as informacoes do estado do formulario, utilizado aqui para verificar o estado de submit do formulario
 * @prop name {string} propriedade do name que o DatePicker ira receber
 */
CustomDatePicker.propType = {
    register: PropType.func,
    errors: PropType.shape({}).isRequired,
    setValueForm: PropType.func,
    triggerValidation: PropType.func,
    formState: PropType.shape({}).isRequired,
    name: PropType.string.isRequired,
};
CustomDatePicker.defaultProps = {
    register: () => null,
    setValueForm: () => null,
    triggerValidation: () => null,
};
