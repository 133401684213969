const URI = process.env.REACT_APP_CHATBOT_URL_BB;
const CLIENT_ID = process.env.REACT_APP_CHATBOT_CLIENT_ID;

export const redirectChatbot = {
    authURL: `${URI}/ui/authorize`,
    responseType: 'code',
    clientId: `${CLIENT_ID}`,
    scope: 'openid-otp+cpf',
    state: 'ca5395e6-f0e4-11ed-a05b-0242ac120003',
};
