import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import FormConfirmarSenhaPrimeiroAcesso from '../../containers/FormConfirmarSenhaPrimeiroAcesso';
import { useStyles } from './styles';
import ReactGTM from '../../utils/gtm';

export default function ConfirmarSenhaPrimeiroAcessoPage() {
    const classes = useStyles();

    useEffect(() => {
        ReactGTM.customData('crie-sua-senha');
    }, []);

    return (
        <Grid
            container
            item
            xs={12}
            justifyContent="center"
            className={classes.container}
        >
            <Grid item container xs={12} className={classes.formContainer}>
                <FormConfirmarSenhaPrimeiroAcesso />
            </Grid>
        </Grid>
    );
}
