/* eslint-disable import/no-named-as-default */
// #region Imports
import { yupResolver } from '@hookform/resolvers';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import api from '../../../../library/api';
import ENDPOINTS from '../../../../consts/endpoints';
import LGPD from '../../../../consts/text/lgpd';
import { genFieldNameAndError } from '../../../../library/utils';
import FormSubmitSuccess from '../FormSubmitSuccess';
import MeioInfoFields from '../MeioInfoFields';
import FIELDS from '../../../../library/acesso-dados-fields';
import { useStyles } from '../styles';
import formOutrasSolicitacoesSchema from './schema';
import { appColors } from '../../../../AppTheme';
// #endregion

const {
    OUTRAS_SOLICITACOES_CONTENT: {
        ESCOLHA_OPCOES,
        ELIMINAR_DADO,
        BLOQUEAR_DADO,
        NAO_RECEBER_INFO,
    },
} = LGPD;

const {
    ACESSO_DADOS: { OUTRAS },
} = ENDPOINTS;

function ToolTipComponent({ title }) {
    const styles = useStyles();
    return (
        <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
            <Tooltip
                title={title}
                arrow
                classes={{ tooltip: styles.tooltipClass }}
            >
                <HelpRoundedIcon color="primary" fontSize="small" />
            </Tooltip>
        </Box>
    );
}

const FormOutrasSolicitacoes = ({ token }) => {
    const styles = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [eliminarDado, setEliminarDado] = useState(false);
    const [bloquearDado, setBloquearDado] = useState(false);
    const [naoReceberInfo, setNaoReceberInfo] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(formOutrasSolicitacoesSchema),
    });

    const handleCheckElChange = () => {
        setEliminarDado(!eliminarDado);
    };

    const handleCheckBloqChange = () => {
        setBloquearDado(!bloquearDado);
    };

    const handleCheckInfoChange = () => {
        setNaoReceberInfo(!naoReceberInfo);
    };

    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (data) => {
        setIsLoading(true);
        await api
            .post(`${OUTRAS}?token=${token}`, data)
            .then(() => {
                enqueueSnackbar('Dados enviados com sucesso!', {
                    variant: 'success',
                });
                setSubmitSuccess(true);
            })
            .catch((err) => {
                const { message } = err.response.data;
                enqueueSnackbar(`${message}`, {
                    variant: 'error',
                });
            });
        setIsLoading(false);
    };

    return (
        <>
            {submitSuccess ? (
                <FormSubmitSuccess {...{ token }} />
            ) : (
                <Box
                    display="flex"
                    alignContent="flex-start"
                    flexDirection="column"
                >
                    <Box color={appColors.gray} padding="2em 0 10px 0">
                        <Typography variant="subtitle1">
                            {ESCOLHA_OPCOES}
                        </Typography>
                    </Box>
                    <form
                        name="outras-solicitacoes"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Box marginY="25px">
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            inputRef={register}
                                            color="primary"
                                            name={
                                                FIELDS.OUTRAS_SOLICITACOES
                                                    .ELIMINAR
                                            }
                                            value={eliminarDado}
                                            onChange={handleCheckElChange}
                                        />
                                    }
                                    label={ELIMINAR_DADO.THIS}
                                />
                                <ToolTipComponent
                                    title={ELIMINAR_DADO.TOOLTIP}
                                />
                            </FormGroup>
                            <FormGroup row>
                                <TextField
                                    inputRef={register}
                                    multiline
                                    rows={4}
                                    {...genFieldNameAndError(
                                        errors,
                                        FIELDS.OUTRAS_SOLICITACOES
                                            .VALOR_ELIMINAR,
                                    )}
                                    placeholder={ELIMINAR_DADO.PLACEHOLDER}
                                    variant="filled"
                                    disabled={!eliminarDado}
                                    className={styles.fieldBox}
                                />
                            </FormGroup>
                        </Box>
                        <Box marginY="25px">
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            inputRef={register}
                                            color="primary"
                                            name={
                                                FIELDS.OUTRAS_SOLICITACOES
                                                    .BLOQUEAR
                                            }
                                            value={eliminarDado}
                                            onChange={handleCheckBloqChange}
                                        />
                                    }
                                    label={BLOQUEAR_DADO.THIS}
                                />
                                <ToolTipComponent
                                    title={BLOQUEAR_DADO.TOOLTIP}
                                />
                            </FormGroup>
                            <FormGroup row>
                                <TextField
                                    inputRef={register}
                                    multiline
                                    rows={4}
                                    {...genFieldNameAndError(
                                        errors,
                                        FIELDS.OUTRAS_SOLICITACOES
                                            .VALOR_BLOQUEAR,
                                    )}
                                    placeholder={BLOQUEAR_DADO.PLACEHOLDER}
                                    variant="filled"
                                    disabled={!bloquearDado}
                                    className={styles.fieldBox}
                                />
                            </FormGroup>
                        </Box>
                        <Box marginY="25px">
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            inputRef={register}
                                            color="primary"
                                            name={
                                                FIELDS.OUTRAS_SOLICITACOES
                                                    .NAO_RECEBER_INFO
                                            }
                                            value={eliminarDado}
                                            onChange={handleCheckInfoChange}
                                        />
                                    }
                                    label={NAO_RECEBER_INFO.THIS}
                                />
                                <ToolTipComponent
                                    title={NAO_RECEBER_INFO.PLACEHOLDER}
                                />
                            </FormGroup>
                        </Box>
                        <Box marginY="25px">
                            <MeioInfoFields {...{ register, errors }} />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            marginTop="20px"
                        >
                            <ButtonSubmit
                                isLoading={isLoading}
                                disabled={isLoading}
                            />
                        </Box>
                    </form>
                </Box>
            )}
        </>
    );
};

export default FormOutrasSolicitacoes;
