/* eslint-disable no-param-reassign */
/**
 * Extrai um parâmetro da URL atual e o decodifica.
 * @param {*} name nome do parâmetro qual deseja extrair.
 * @param {*} url Endereço da página atual.
 */
export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
