import './fonts.css';

export const FONTS = {
    ArialMT: {
        fontFamily: 'ArialMTBold',
    },
    ArialMTLight: {
        fontFamily: 'ArialMTLight',
    },
    ArialMTBold: {
        fontFamily: 'ArialMTBold',
    },
};

export default FONTS;
