import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SelectOption from '../../components/SelectOption';
import ROUTES_NAME from '../../consts/routes-name';
import { CONFIRMACAO_TXTS } from '../../consts/text/confirmacao-seguranca';
import { AutenticationActions } from '../../store/ducks/autentication';
import * as session from '../../utils/session';
import {
    onlyNums,
    telefoneMascarado,
} from '../../utils/normalizers/normalizers';
import { useStyles } from './styles';
import ReactGTM from '../../utils/gtm';

export default function SelecaoEnvioCodigoPrimeiroAcesso() {
    const classes = useStyles();
    const history = useHistory();

    const { dadosMascarados } = useSelector((state) => ({
        dadosMascarados: state.autenticacao?.dadosMascarados,
    }));

    const dispatch = useDispatch();
    const userName = session.getUserNamePrimeiroAcesso();

    useEffect(() => {
        if (!dadosMascarados && userName) {
            dispatch(
                AutenticationActions.getDadosMascaradosRequest({
                    cpfCnpj: userName,
                    isPrimeiroAcesso: true,
                    tipoPessoa: onlyNums(userName).length > 11 ? 'PJ' : 'PF',
                }),
            );
            window.history.replaceState({}, null);
        }
    }, [dispatch, userName, dadosMascarados]);

    useEffect(() => {
        const userNamePrimeiroAcesso = session.getUserNamePrimeiroAcesso();
        if (!userNamePrimeiroAcesso) {
            history.push(ROUTES_NAME.HOME);
        }
    }, [history]);

    useEffect(() => {
        ReactGTM.customData('receber-codigo-acesso');
    }, []);

    return (
        <Grid
            container
            className={classes.root}
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                square
                elevation={0}
                component={Paper}
                className={classes.containerGrid}
                container
                spacing={2}
                direction="row"
            >
                <Grid item xs={12}>
                    <Typography variant="h2" className={classes.titulo}>
                        {CONFIRMACAO_TXTS.TITLE(true)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.subTitulo}>
                        {CONFIRMACAO_TXTS.SUBTITLE(dadosMascarados?.telefone)}
                    </Typography>
                </Grid>
                <SelectOption
                    isPrimeiroAcesso
                    email={dadosMascarados?.email}
                    celular={telefoneMascarado(dadosMascarados?.telefone)}
                    onSubmitSelectCard={(value) => {
                        dispatch(
                            AutenticationActions.postEnviarCodigoPrimeiroAcessoRequest(
                                {
                                    cpfCnpj: userName,
                                    tipoEnvio: value?.toUpperCase(),
                                    tipoPessoa:
                                        onlyNums(userName).length > 11
                                            ? 'PJ'
                                            : 'PF',
                                },
                            ),
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
}
