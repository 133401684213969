import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    loading: {
        color: theme.appColors.primaryBlue,
        backgroundColor: 'transparent !important',
        marginRight: 10,
    },
    root: {
        '& .MuiFormLabel-root': {
            color: theme.appColors.darkGray,
            fontSize: '14px !Important',
            fontFamily: theme.typography.inter.fontFamily,
        },
        // '& .MuiOutlinedInput-adornedEnd': {paddingRight: 0},
        '& .MuiInputBase-root.Mui-disabled': {
            // backgroundColor: '#e7e7e7e7',
            color: 'rgba(0, 0, 0, 0.85) !important',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '12px !Important',
            transform: 'translate(16px, -6px) scale(0.95)',
        },
        '& .MuiInputLabel-outlined': {
            backgroundColor: 'transparent!important',
        },
        '& .MuiInputBase-input': {
            color: theme.appColors.darkGray,
            fontFamily: theme.typography.inter.fontFamily,
            letterSpacing: '-0.13px',
            lineHeight: '24px',
            fontSize: 14,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: theme.appColors.cardBorderInative,
        },
        '& .MuiFormHelperText-root': {
            fontFamily: 'Inter',
        },
    },
}));
