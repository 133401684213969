/* eslint-disable import/no-named-as-default */
// #region Imports

import { yupResolver } from '@hookform/resolvers';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import api from '../../../../library/api';
import ENDPOINTS from '../../../../consts/endpoints';
import LGPD from '../../../../consts/text/lgpd';
import { genFieldNameAndError } from '../../../../library/utils';
import MeioInfoFields from '../MeioInfoFields';
import FIELDS from '../../../../library/acesso-dados-fields';
import FormSubmitSuccess from '../FormSubmitSuccess/index';
import { useStyles } from '../styles';
import formConsultaDataSchema from './schema';
import { appColors } from '../../../../AppTheme';

// #endregion

const {
    CONSULTA_DADOS_CONTENT: { COPIA_DADOS, INFO_ADICIONAIS },
} = LGPD;

const {
    ACESSO_DADOS: { CONSULTA },
} = ENDPOINTS;

const FormConsultaDados = ({ token }) => {
    const styles = useStyles();
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, control, errors } = useForm({
        resolver: yupResolver(formConsultaDataSchema),
    });

    const receberCopia = useWatch({
        control,
        name: FIELDS.CONSULTA_DADOS.RECEBER_COPIA,
    });

    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (data) => {
        setIsLoading(true);
        await api
            .post(`${CONSULTA}?token=${token}`, data)
            .then(() => {
                enqueueSnackbar('Dados enviados com sucesso!', {
                    variant: 'success',
                });
                setSubmitSuccess(true);
            })
            .catch((err) => {
                const { status, statusText } = err.response;
                const { message } = err.response.data;
                enqueueSnackbar(`${status} - ${message || statusText}`, {
                    variant: 'error',
                });
            });
        setIsLoading(false);
    };

    return (
        <>
            {submitSuccess ? (
                <FormSubmitSuccess {...{ token }} />
            ) : (
                <Box display="flex" flexDirection="column">
                    <Box color={appColors.gray} padding="2em 0 10px 0">
                        <Typography variant="subtitle1">
                            {COPIA_DADOS.THIS}
                        </Typography>
                    </Box>
                    <form
                        name="consulta-dados"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <FormGroup row>
                            <Controller
                                control={control}
                                name={FIELDS.CONSULTA_DADOS.RECEBER_COPIA}
                                defaultValue={false}
                                render={({ onChange, value }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                onChange={(e) =>
                                                    onChange(e.target.checked)
                                                }
                                                checked={value}
                                            />
                                        }
                                        label={COPIA_DADOS.RECEBER}
                                    />
                                )}
                            />
                        </FormGroup>
                        <MeioInfoFields
                            disabled={!receberCopia}
                            {...{ register, errors }}
                            isTelaConsulta
                        />
                        <Box
                            color={appColors.gray}
                            marginTop="20px"
                            paddingY="10px"
                        >
                            <Typography variant="subtitle1">
                                {INFO_ADICIONAIS.THIS}
                            </Typography>
                        </Box>
                        <FormGroup row>
                            <TextField
                                inputRef={register}
                                multiline
                                rows={4}
                                {...genFieldNameAndError(
                                    errors,
                                    FIELDS.CONSULTA_DADOS.INFO_ADICONAIS,
                                )}
                                placeholder={INFO_ADICIONAIS.PLACEHOLDER}
                                variant="outlined"
                                disabled={!receberCopia}
                                className={styles.fieldBox}
                            />
                        </FormGroup>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            marginTop="20px"
                        >
                            <ButtonSubmit
                                disabled={!receberCopia || isLoading}
                                isLoading={isLoading}
                            />
                        </Box>
                    </form>
                </Box>
            )}
        </>
    );
};

export default FormConsultaDados;
