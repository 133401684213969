import { yupResolver } from '@hookform/resolvers';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import CustomInputForm from '../../components/CustomInputForm/CustomInputForm';
import {
    FORM_DADOS_PESSOAIS as fpText,
    FORM_DADOS_PESSOAIS_FIELDS as fpFields,
} from '../../consts/text';
import PRIMEIRO_ACESSO from '../../consts/text/primeiro-acesso';
import { AutenticationActions } from '../../store/ducks/autentication';
import { masks } from '../../utils';
import ModalErroPrimeiroAcesso from '../../components/ModalErroPrimeiroAcesso/ModalErroPrimeiroAcesso';
import { formSchemaPrimeiroAcesso } from './form-schema';
import { useStyles } from './styles';
import * as session from '../../utils/session';
import ReactGTM from '../../utils/gtm';

export default function FormPrimeiroAcesso() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        errors,
        register,
        handleSubmit,
        getValues,
        formState,
        triggerValidation,
        setValue,
    } = useForm({
        resolver: yupResolver(formSchemaPrimeiroAcesso),
    });

    const isChatbot = session.getChatbot();
    const tagId = isChatbot
        ? 'continuar-cadastro-whats-001'
        : 'primeiro-acesso';
    const nameGtm = isChatbot ? 'contiunar-cadastro' : 'continuar';

    const submitForm = (data) => {
        dispatch(
            AutenticationActions.getConsultaExistenciaDocumentoRequest({
                tipoPessoa: 'PF',
                ...data,
            }),
        );
    };

    const validadeDiffDate = (diff) => {
        const dtNow = new Date();
        const year = dtNow.getFullYear();
        dtNow.setFullYear(year - diff);
        return dtNow;
    };

    const onErrorForm = (err) => {
        //* * Google Analytics */
        ReactGTM.eventErrorsForm(err);
    };

    return (
        <>
            <Grid item xs={12}>
                <CustomInputForm
                    name={fpFields.CPF_CNPJ}
                    label={fpText.CPF}
                    inputRef={register}
                    errors={errors}
                    shrink={Boolean(getValues(fpFields.CPF_CNPJ))}
                    inputProps={{
                        mask: masks.cpfMask,
                        showMask: false,
                        inputMode: 'numeric',
                    }}
                    checkValid
                    data-gtm-form="input"
                    data-gtm-name="cpf"
                />
            </Grid>
            <Grid item xs={12}>
                <CustomDatePicker
                    name={fpFields.DATA}
                    label={fpText.DATA_NASCIMENTO}
                    placeholder="Selecionar"
                    errors={errors}
                    setValueForm={setValue}
                    register={register}
                    triggerValidation={triggerValidation}
                    formState={formState}
                    maxDate={validadeDiffDate(18)}
                    minDate={validadeDiffDate(100)}
                    initialValue={getValues(fpFields.DATA_NASCIMENTO)}
                    data-gtm-form="input"
                    data-gtm-name="data-nascimento"
                />
            </Grid>

            <Grid item xs={12} container justifyContent="center">
                <CustomButton
                    className={classes.Button}
                    content={PRIMEIRO_ACESSO.BUTTON}
                    variant="contained"
                    onClickCallBack={handleSubmit(submitForm, onErrorForm)}
                    gtm={{
                        id: tagId,
                        'data-gtm-name': nameGtm,
                    }}
                />
            </Grid>
            <ModalErroPrimeiroAcesso />
        </>
    );
}
