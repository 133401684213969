/* eslint-disable import/no-named-as-default */
import ENDPOINTS from '../consts/endpoints';
import api from '../library/api';

export default class AutenticationService {
    constructor() {
        this.api = api;
    }

    postPrimeiroAcesso = (data) => {
        return this.api.post(ENDPOINTS.URL_PRIMEIRO_ACESSO, data, {
            noAuthorization: true,
        });
    };

    postLogin = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_LOGIN, data, {
            noAuthorization: true,
        });
    };

    postLoginBb = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_LOGIN_BB, data, {
            noAuthorization: true,
        });
    };

    postCriarUsuario = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_USER, data, {
            noAuthorization: true,
        });
    };

    postAlterarSenha = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_ALTERAR_SENHA, data, {
            noAuthorization: true,
        });
    };

    putDefinirSenha = (data) => {
        return this.api.put(
            ENDPOINTS.URL_AUTH_DEFINIR_SENHA_PRIMEIRO_ACESSO,
            data,
            {
                noAuthorization: true,
            },
        );
    };

    postEsqueciSenha = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_ESQUECI_SENHA, data, {
            noAuthorization: true,
        });
    };

    postValidarToken = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_VALIDAR_TOKEN, data, {
            noAuthorization: true,
        });
    };

    postEnviarToken = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_ENVIAR_TOKEN, data, {
            noAuthorization: true,
        });
    };

    postEnviarCodigoPrimeiroAcesso = (data) => {
        return this.api.post(
            ENDPOINTS.URL_AUTH_ENVIAR_CODIGO_PRIMEIRO_ACESSO,
            data,
            {
                noAuthorization: true,
            },
        );
    };

    postInserirGrupo = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_INSERIR_GRUPO, data, {
            noAuthorization: true,
        });
    };

    getAuthParceiro = ({ idParceiro, RefererOrigin }) => {
        return this.api.get(ENDPOINTS.URL_AUTH_PARCEIROS.concat(idParceiro), {
            headers: { RefererOrigin },
        });
    };

    postBuscarPerfil = (data) => {
        return this.api.post(ENDPOINTS.URL_AUTH_BUSCAR_PERFIL, data, {
            noAuthorization: true,
        });
    };

    getURL = () => {
        return this.api.get(ENDPOINTS.AUTH_UUID(), {
            noAuthorization: true,
        });
    };

    getDadosMascarados = ({ email }) => {
        return this.api.get(ENDPOINTS.GET_DADOS_MASCARADOS(email), {
            noAuthorization: true,
        });
    };

    getDadosMascaradosPrimeiroAcesso = ({ cpfCnpj, tipoPessoa }) => {
        return this.api.get(
            ENDPOINTS.GET_DADOS_MASCARADOS_PRIMEIRO_ACESSO(cpfCnpj, tipoPessoa),
            {
                noAuthorization: true,
            },
        );
    };

    consultaExistenciaDocumentos = ({ cpfCnpj, data, tipoPessoa }) => {
        return this.api.get(
            ENDPOINTS.CONSULTA_EXISTENCIA_DOCUMENTO(cpfCnpj, data, tipoPessoa),
            {
                noAuthorization: true,
            },
        );
    };

    postValidarTokenPrimeiroAcesso = (data) => {
        return this.api.post(
            ENDPOINTS.VALIDAR_CODIGO_ACESSO_PRIMEIRO_ACESSO,
            data,
            {
                noAuthorization: true,
            },
        );
    };
}
