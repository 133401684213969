/* eslint-disable import/no-named-as-default */
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DoubleCardIcon from '../../assets/icons/double-card-icon-blue.svg';
import MonitorIcon from '../../assets/icons/monitor-icon-blue.svg';
import { useStyles } from './styles';
import CardAccess from '../../components/CardAccess';
import {
    ACCESS_PORTAL_ENUM,
    ACCESS_PORTAL_TEXT,
    PORTAL_LOGIN_TYPE,
} from '../../consts/text/form-login';
import CustomCarousel from '../../components/CustomCarousel';
import * as session from '../../utils/session';
import { redirectChatbot } from './consts';
import ENDPOINTS from '../../consts/endpoints';

export const PortalAccessCards = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('1210'));

    const { autenticacao } = useSelector((state) => ({
        autenticacao: state.autenticacao,
    }));

    const loginType = session.getChatbot();

    const { authURL, responseType, clientId, scope } = redirectChatbot;
    const redirectUri = ENDPOINTS.URL_LOGIN_CHATBOT;

    const hrefURLChatbot = `${authURL}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${session.getHashChatbot()}&response_type=${responseType}+state`;

    const href = loginType ? hrefURLChatbot : autenticacao?.url;

    const tagIds = {
        portalCorrentista: loginType ? 'acesso-bb-whats-001' : 'acesso-bb-001',
        portalNaoCorrentista: loginType
            ? 'acesse-aqui-whats-001'
            : 'acesse-aqui-001',
    };

    const portalCorrentista = {
        id: ACCESS_PORTAL_ENUM.PORTAL_CORRENTISTA,
        title: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_TITLE,
        description: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_DESCRIPTION,
        btnText: ACCESS_PORTAL_TEXT.PORTAL_CORRENTISTA_ICON_TEXT,
        icon: DoubleCardIcon,
        href,
        gtm: {
            'data-gtm-type': 'click',
            'data-gtm-clicktype': 'button',
            id: tagIds.portalCorrentista,
            'data-gtm-name': 'acesse-com-senha-bb',
        },
    };

    const portalNaoCorrentista = {
        id: ACCESS_PORTAL_ENUM.PORTAL_NAO_CORRENTISTA,
        title: ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_TITLE,
        description: ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_DESCRIPTION,
        btnText: ACCESS_PORTAL_TEXT.PORTAL_NAO_CORRENTISTA_ICON_TEXT,
        icon: MonitorIcon,
        showLoginForm: true,
        gtm: {
            'data-gtm-type': 'click',
            'data-gtm-clicktype': 'button',
            id: tagIds.portalNaoCorrentista,
            'data-gtm-name': 'acesse-aqui',
        },
    };

    const portalItens = useMemo(() => {
        if (loginType === PORTAL_LOGIN_TYPE.CORRENTISTA)
            return [portalCorrentista];
        if (loginType === PORTAL_LOGIN_TYPE.NAO_CORRENTISTA)
            return [portalNaoCorrentista];
        return [portalCorrentista, portalNaoCorrentista];
    }, [portalCorrentista, portalNaoCorrentista, loginType]);

    return (
        <Grid container>
            <Grid
                container
                className={classes.gridContainerPortalAccess}
                justifyContent="center"
            >
                <Grid
                    container
                    className={classes.gridMaxWidth}
                    justifyContent="space-around"
                >
                    {isMd ? (
                        <CustomCarousel
                            showDots
                            showArrows={false}
                            itemsLenght={portalItens.length}
                        >
                            {portalItens.map((item) => (
                                <CardAccess key={item.id} {...item} />
                            ))}
                        </CustomCarousel>
                    ) : (
                        <>
                            {portalItens.map((item) => (
                                <CardAccess key={item.id} {...item} />
                            ))}
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
