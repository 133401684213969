/* eslint-disable import/no-named-as-default */
import React from 'react';
import ENDPOINTS from '../endpoints';

const LGPD = {
    TITLE: 'Acesso aos dados pessoais',
    PARAGRAPH: (
        <div>
            Nossa prioridade é zelar pela privacidade e segurança das
            informações de nossos clientes e informar com transparência como
            tratamos seus dados. Clique aqui para acessar nossa{' '}
            <a
                href={ENDPOINTS.POLITICA_PRIVACIDADE}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'rgb(78, 78, 78)' }}
            >
                política de privacidade
            </a>
            . Abaixo, você poderá exercer seus direitos escolhendo uma ou mais
            opções.
        </div>
    ),
    TABS: {
        CONSULTA_DADOS: 'Consulta',
        ATUALIZACAO_DADOS: 'Atualização',
        OUTRAS_SOLICITAÇÕES: 'Outras solicitações',
    },
    CONSULTA_DADOS_CONTENT: {
        COPIA_DADOS: {
            THIS: 'Para obter uma cópia dos seus dados pessoais tratados pela BB Seguros, preencha a requisição a seguir:',
            RECEBER: 'Quero receber uma cópia dos meus dados pessoais.',
        },
        INFO_ADICIONAIS: {
            THIS: 'Informações adicionais, se necessário:',
            PLACEHOLDER: 'Descreva sua necessidade',
        },
    },
    OUTRAS_SOLICITACOES_CONTENT: {
        ESCOLHA_OPCOES: 'Escolha as opções a seguir:',
        ELIMINAR_DADO: {
            THIS: 'Eliminar dados pessoais',
            PLACEHOLDER: 'Informe a relação de dados que deseja eliminar...',
            TOOLTIP:
                'Solicitação de exclusão definitiva de dado ou de conjunto de dados.',
        },
        BLOQUEAR_DADO: {
            THIS: 'Bloquear dados pessoais',
            PLACEHOLDER: 'Informe a relação de dados que deseja bloquear...',
            TOOLTIP: 'Suspensão temporária da utilização dos seus dados.',
        },
        NAO_RECEBER_INFO: {
            THIS: 'Deixar de receber informações publicitárias',
            PLACEHOLDER: 'Suspensão de envio de comunicação publicitária',
        },
    },
    INFO_MEIO: {
        CONSULTA_MEIO: 'Informar por qual meio gostaria de receber a resposta:',
        OUTRAS_MEIO:
            'Informar por qual meio gostaria de receber informações sobre o andamento da sua solicitação:',
        EMAIL: {
            THIS: 'E-mail',
            PLACEHOLDER: 'Informe um e-mail válido...',
        },
        CORRESPONDENCIA: {
            THIS: 'Correspondência',
            PLACEHOLDER: 'Informe seu endereço completo...',
        },
    },
    ATUALIZACAO_DADOS_CONTENT: {
        INFO: 'Para correção de dados relacionados a produtos e serviços, ligue para nossa central de atendimento:',
        LIGAR_BB_SEGUROS: {
            THIS: 'Ligar para a BB Seguros',
            NUMERO: '0800 729 7000',
        },
        ATENDIMENTO: {
            THIS: 'Horário de atendimento:',
            HORARIO: '9 às 18 horas',
        },
        LIGAR_DEFICIENTES_AUDITIVOS: {
            THIS: 'Deficientes auditivos',
            NUMERO: '0800 962 7373',
        },
    },
};

export default LGPD;
